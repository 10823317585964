<template>
  <div class="mx-10 py-4">loading...</div>
</template>

<script>
export default {
  name: "QREntry",
  created() {
    const [prefix, qr_id] = this.$route.params.qr_id.split("_");
    if (prefix == "t") {
      this.$store.dispatch("tickets/getTicketWithQR", {
        id: qr_id,
        cb: data =>
          this.$router.push({
            name: "Ticket-Detail",
            params: { id: data.data.id }
          })
      });
      // if (prefix == "cl") {
      //   this.$store.dispatch("checkList/getCheckList", {
      //     id: qr_id,
      //     cb: data => {
      //       // startCheckList(data);
      //       this.$router.push({ name: "Home", params: { startChecklist: true } });
      //     }
      //   });
      // } else if (prefix == "b") {
      //   this.$router.push({ name: "Asset" });
      //   this.$store.dispatch("assets/getAsset", {
      //     id: qr_id,
      //     cb: data => {
      //       startChecklistSelect(data.data);
      //     }
      //   });
      // } else if (prefix == "h") {
      //   this.$router.push({ name: "Hubs" });
      //   this.$store.dispatch("hubs/getHub", {
      //     id: qr_id,
      //     cb: data => {
      //       this.$store.commit("drawer/RESET_DRAWER");
      //       this.$store.commit("drawer/OPEN_DRAWER", "actionDrawer");
      //       this.$store.commit("drawer/SET_HUB", data);
      //     }
      //   });
      // } else if (prefix == "bk") {
      //   // router.push({ name: "CheckList", params: { check_list_id: 1 } });
      //   this.$store.dispatch("checkouts/getCheckout", {
      //     id: qr_id,
      //     cb: data => {
      //       this.$store.commit("drawer/RESET_DRAWER");
      //       this.$store.commit("drawer/OPEN_DRAWER", "checkoutDrawer");
      //       this.$store.commit("drawer/SET_ASSET", data.bike);
      //       this.$store.commit("drawer/SET_CHECKOUT", data);
      //       this.$router.push({ name: "Home" });
      //       // router.push({
      //       //   name: "CheckList",
      //       //   params: {
      //       //     checklistId: data?.start_checklist_result?.checklist_id,
      //       //     checkListResultId: data?.start_checklist_result?.id,
      //       //   },
      //       // });
      //     }
      //   });
    } else {
      this.$notify.error({
        title: "Error",
        message: "QR doesn't exist"
      });
      this.$router.push({ name: "Home" });
      // ElMessageBox.alert("Not a valid Vault QR", "Warning", {
      //   confirmButtonText: "OK"
      // }).then(() => {
      //   router.push({ name: "Home" });
      // });
    }
  }
};
</script>
